import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Section from "../../../components-v2/Base/Section";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import heroBg from "../../../images/general-pos-og.png";
import LeadForm from "../../../components/Forms/LeadForm";
import data, { generalPosData } from "../../../data/forms/lead-form";
import { extractDefaultValues } from "../../../utils/form-utils";
import bg1 from "../../../images/svg/vector-10.svg";
import b1 from "../../../images/random/badge-101.png";
import b2 from "../../../images/random/badge-102.png";
import b3 from "../../../images/random/badge-103.png";
import b4 from "../../../images/random/badge-104.png";
import b5 from "../../../images/random/badge-105.png";
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import TopRestaurantsLogos from "../../../components-v2/TopRestaurantsLogos";
import Image from "../../../components/ImageQuerys/RandomImages";
import { SPOTON_TEAMWORK } from "../../../utils/firebase/forms";
import check from "../../../images/svg/checkmark.svg";
import reviews from "../../../data/reviews";

function Teamwork({}) {
  return (
    <Layout
      className="non-fixed-width"
      isLanding
      useNewLPHeader
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="Restaurant Labor Management Software | SpotOn"
        description="Save time and cut labor costs with smart scheduling, employee communication, tip management, and payroll prep in one system. Learn more."
        image={`https://spoton.com/${heroBg}`}
      />

      <Section className="grid grid-cols-1 lg:grid-cols-2 mt-20 md:mt-32 items-center gap-10">
        <div className="text-center lg:text-left">
          <Heading level={1} injectionType={1} withBlueSymbol>
            Save time & money managing your staff with SpotOn Teamwork
          </Heading>
          <div className="text-black-300 text-[20px] max-w-[400px] mt-10 mx-auto lg:mx-0 relative z-1">
            Simplify team management by bringing scheduling, tip distribution,
            payroll, and employee collaboration into one platform.
          </div>

          <div>
            <Heading
              withBlueSymbol={false}
              level={2}
              injectionType={4}
              className="text-black max-w-[800px] mx-auto mt-8 relative z-1 hidden md:block"
            >
              Committed to helping your business grow through innovation and
              unmatched customer support.
            </Heading>
            <div className="mt-8 md:mt-0 mx-auto flex flex-wrap gap-3 items-center justify-between md:justify-between lg:justify-start xl:justify-between">
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b1}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b2}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b3}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b4}
                alt="badge"
              />
              <img
                className="hidden md:inline max-w-[22%] md:max-w-[100px]"
                src={b5}
                alt="badge"
              />
            </div>
          </div>
        </div>

        <div className="relative">
          <LeadForm
            className="relative z-[1]"
            plain
            noHeader
            formName="Lead Form"
            displayForm
            formData={data}
            initialValues={extractDefaultValues(data)}
            onValuesChange={() => null}
            leadType="restaurants"
            formValueForDataLayerEventName="industry"
            dataLayerEventNameAppendix="-lead"
            withLineOfBusinessOption={false}
            lineOfBusiness="Hospitality"
            productInterest=""
            collection={SPOTON_TEAMWORK}
            thankYouPath="/lp/teamwork/thank-you"
          />
          <img
            src={bg1}
            alt="blue circle"
            className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
          />
        </div>
      </Section>

      <Section fullWidth className="bg-black mt-10">
        <div className="text-white grid grid-cols-1 lg:grid-cols-2 gap-y-10 gap-x-4 max-w-[1000px] mx-auto lg:py-40">
          <div className="text-center mx-auto w-full lg:mx-0 lg:text-left">
            <Heading
              className="text-white"
              withBlueSymbol
              level={2}
              injectionType={1}
            >
              Get out of your back office
            </Heading>

            <p className="text-black-600 text-p lg:max-w-[380px]">
              How many hours per week are you spending on labor management?
              SpotOn Teamwork syncs with your point-of-sale to streamline admin
              work and get you back on the floor to attend to your staff and
              guests. Current Teamwork clients say it saves them up to 20 hours
              per week.
            </p>
            <div className="font-bold lg:max-w-[400px] gap-y-6 flex flex-col mt-8">
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Data-driven schedules with premade templates and drag-and-drop
                  tools
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Customizable labor compliance rules with enforced clock-ins to
                  protect your business
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  An employee app for your team to view schedules, swap shifts,
                  and request PTO from their phone
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Payroll & tip management to simplify payday with POS
                  integration and accuracy checks
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Real humans to set it all up for you, with 24/7 support after
                  you launch
                </span>
              </div>
            </div>
          </div>

          <div className="relative -mb-32 ">
            <Image
              imageName="teamwork-asset.png"
              alt="products"
              className="relative lg:absolute left-0 right-0 top-0"
            />
          </div>
        </div>
      </Section>

      <Section className="mt-40 xl:mt-48">
        <TestmonialReviews
          sectionData={{
            reviews,
            title: "What SpotOn customers are saying",
            subTitle: "Read some of our 1500+ five star customer reviews.",
          }}
        />
      </Section>

      <TopRestaurantsLogos
        desktopImageName="teamwork-logos-desktop.png"
        mobileImageName="teamwork-logos-mobile.png"
      />
    </Layout>
  );
}

export default Teamwork;
